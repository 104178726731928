// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-lakeview-agc-jsx": () => import("./../../../src/pages/about-lakeview-agc.jsx" /* webpackChunkName: "component---src-pages-about-lakeview-agc-jsx" */),
  "component---src-pages-admin-add-event-jsx": () => import("./../../../src/pages/admin/add-event.jsx" /* webpackChunkName: "component---src-pages-admin-add-event-jsx" */),
  "component---src-pages-admin-add-fasts-jsx": () => import("./../../../src/pages/admin/add-fasts.jsx" /* webpackChunkName: "component---src-pages-admin-add-fasts-jsx" */),
  "component---src-pages-admin-add-sermon-jsx": () => import("./../../../src/pages/admin/add-sermon.jsx" /* webpackChunkName: "component---src-pages-admin-add-sermon-jsx" */),
  "component---src-pages-admin-admin-layout-jsx": () => import("./../../../src/pages/admin/adminLayout.jsx" /* webpackChunkName: "component---src-pages-admin-admin-layout-jsx" */),
  "component---src-pages-admin-events-jsx": () => import("./../../../src/pages/admin/events.jsx" /* webpackChunkName: "component---src-pages-admin-events-jsx" */),
  "component---src-pages-admin-index-jsx": () => import("./../../../src/pages/admin/index.jsx" /* webpackChunkName: "component---src-pages-admin-index-jsx" */),
  "component---src-pages-admin-sermons-jsx": () => import("./../../../src/pages/admin/sermons.jsx" /* webpackChunkName: "component---src-pages-admin-sermons-jsx" */),
  "component---src-pages-church-ministries-jsx": () => import("./../../../src/pages/church-ministries.jsx" /* webpackChunkName: "component---src-pages-church-ministries-jsx" */),
  "component---src-pages-get-in-touch-jsx": () => import("./../../../src/pages/get-in-touch.jsx" /* webpackChunkName: "component---src-pages-get-in-touch-jsx" */),
  "component---src-pages-home-fellowship-and-bible-study-jsx": () => import("./../../../src/pages/home-fellowship-and-bible-study.jsx" /* webpackChunkName: "component---src-pages-home-fellowship-and-bible-study-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lakeview-academy-jsx": () => import("./../../../src/pages/lakeview-academy.jsx" /* webpackChunkName: "component---src-pages-lakeview-academy-jsx" */),
  "component---src-pages-prayer-and-fasting-jsx": () => import("./../../../src/pages/prayer-and-fasting.jsx" /* webpackChunkName: "component---src-pages-prayer-and-fasting-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

